import { styled } from 'stitches.config';
import { PlayroomBaseModal } from '../PlayroomBaseModal';

type ConfirmModifyCancelModalProps = {
  onClose: () => unknown;
  onConfirm: () => unknown;
};

export default function ConfirmModifyCancelModal({
  onClose,
  onConfirm,
}: ConfirmModifyCancelModalProps) {
  const handleConfirm = async () => {
    onConfirm();
  };

  return (
    <PlayroomBaseModal
      title="플레이룸 수정을 그만둘까요?"
      content={
        <Content>
          지금 페이지를 나가면 <br />
          수정한 정보는 저장되지 않아요.
        </Content>
      }
      okText="그만두기"
      cancelText="취소"
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
}

const Content = styled('div', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  textAlign: 'center',
});
