import MobileHeader from '@src/components/MobileHeader';
import PlayLayout from '@src/components/PlayLayout';
import { styled } from 'stitches.config';
import LeftIcon from '@assets/icons/leading-icn.svg';
import ChevronRightIcon from '@assets/icons/chevron-right.svg';
import AlertIcon from '@assets/icons/exclamation-mark-filled.svg';
import React from 'react';
import { navigate } from 'gatsby';
import Input from '@src/components/Input';
import Toggle from '@src/components/Toggle';
import Sheet from 'react-modal-sheet';
import SheetHeader from '@src/components/Playroom/SheetHeader';
import WheelPicker from 'react-simple-wheel-picker';
import { useGetRoomQuery } from '@src/apis/queries';
import {
  usePostModifyRoomMutation,
  usePostModifyRoomStatusMutation,
} from '@src/apis/mutation';
import { Controller, useForm } from 'react-hook-form';
import { UpdateRoomRequestDtoStatusEnum } from '@uniquegood/realworld-play-interface/dist';
import { roomStatusToLabel } from '@src/constants/playroom';
import Modal, { useModal } from '@src/components/Modal';
import ConfirmModifyCancelModal from '@src/components/Modal/play/ConfirmModifyCancelModal';
import { useToastContext } from '@src/providers/Toast';

interface PlayroomInfoProps {
  params: {
    id: string;
  };
}

export default function PlayroomSetting(props: PlayroomInfoProps) {
  const { id } = props.params;

  const [isPrivate, setIsPrivate] = React.useState(false);
  const [isUserCountSheetOpen, setIsUserCountSheetOpen] = React.useState(false);
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] =
    React.useState(false);
  const [selectedUserCount, setSelectedUserCount] = React.useState(2);
  const [selectedStatus, setSelectedStatus] =
    React.useState<UpdateRoomRequestDtoStatusEnum>('Waiting');
  const selectedUserCountRef = React.useRef(selectedUserCount);

  const { showToast } = useToastContext();

  const { data: roomData, isLoading } = useGetRoomQuery(id, true);
  const { mutateAsync: modifyRoom } = usePostModifyRoomMutation();
  const { mutateAsync: modifyRoomStatus } = usePostModifyRoomStatusMutation();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isDirty },
  } = useForm();

  const { openModal, closeModal, modal } = useModal();

  const handlePrevClick = () => {
    if (isDirty) {
      openModal(
        <ConfirmModifyCancelModal
          onClose={closeModal}
          onConfirm={() => {
            closeModal();

            navigate(-1);
          }}
        />
      );
    } else {
      navigate(-1);
    }
  };

  const handleUserCountChangeClick = () => {
    setSelectedUserCount(selectedUserCountRef.current);

    setIsUserCountSheetOpen(false);
  };

  const handleClose = () => {
    setIsUserCountSheetOpen(false);
  };

  const handleSubmitClick = () => {
    handleSubmit(async (formData) => {
      const data = await modifyRoom({
        roomId: id,
        infoObject: {
          title: formData.title,
          password: isPrivate ? formData.password : null,
          status: selectedStatus,
          maxUserCount: selectedUserCount,
        },
      });

      if (data.success) {
        const data = await modifyRoomStatus({
          roomId: id,
          statusObject: {
            status: selectedStatus,
          },
        });

        showToast('플레이룸이 수정되었습니다.');

        if (data?.success) {
          navigate(`/play/rooms/${id}/info`, { replace: true });
        }
      }
    })();
  };

  const userCountData = Array.from({ length: 7 }, (_, i) => ({
    id: `${i + 2}`,
    value: `${i + 2}인`,
  }));

  React.useEffect(() => {
    setIsPrivate(roomData?.data?.isPrivateRoom || false);
    setSelectedUserCount(roomData?.data?.maxUserCount || 2);
    setSelectedStatus(roomData?.data?.status || 'Waiting');

    setValue('title', roomData?.data?.title || '');
    setValue('password', roomData?.data?.password || '');
  }, [roomData]);

  return (
    <>
      <PlayLayout>
        <MobileHeader
          left={
            <Button onClick={handlePrevClick}>
              <img src={LeftIcon} alt="뒤로 가기 아이콘" />
            </Button>
          }
          center="플레이룸 설정"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 48px)',
          }}
        >
          <div style={{ flexGrow: 1, flexShrink: 1, padding: '0 16px' }}>
            <Label style={{ display: 'inline-block', marginBottom: '8px' }}>
              플레이룸 제목
            </Label>
            <Controller
              name="title"
              control={control}
              rules={{
                maxLength: {
                  value: 20,
                  message: '제목은 최대 20자까지 작성 가능해요.',
                },
              }}
              render={({ field }) => (
                <Input
                  placeholder="{닉네임}님의 방"
                  isError={Boolean(errors.title?.message)}
                  {...field}
                />
              )}
            />
            {errors.title?.message && (
              <ErrorText>
                <img
                  src={AlertIcon}
                  alt="경고 아이콘"
                  style={{ marginRight: '4px' }}
                />
                {errors.title?.message as string}
              </ErrorText>
            )}
            <Content>
              <Label>플레이할 게임</Label>
              <DisabledText>{roomData?.data?.roomProject.name}</DisabledText>
            </Content>
            <Content>
              <Label>인원 수</Label>
              <SelectButton onClick={() => setIsUserCountSheetOpen(true)}>
                <span style={{ verticalAlign: 'middle' }}>
                  {selectedUserCount ? `${selectedUserCount}인` : '선택하기'}
                </span>
                <img
                  src={ChevronRightIcon}
                  alt="오른쪽 화살표"
                  style={{ verticalAlign: 'middle' }}
                />
              </SelectButton>
            </Content>
            <Content>
              <Label>모집 상태</Label>
              <SelectButton onClick={() => setIsStatusChangeModalOpen(true)}>
                <span style={{ verticalAlign: 'middle' }}>
                  {roomStatusToLabel[selectedStatus]}
                </span>
                <img
                  src={ChevronRightIcon}
                  alt="오른쪽 화살표"
                  style={{ verticalAlign: 'middle' }}
                />
              </SelectButton>
            </Content>
            <Content>
              <Label>비밀번호 설정</Label>
              <Toggle
                checked={isPrivate}
                onChange={() => setIsPrivate((prev) => !prev)}
              />
            </Content>
            {isPrivate && (
              <Controller
                name="password"
                control={control}
                rules={{
                  validate: (value) => {
                    if (
                      !Number.isNaN(Number(value)) &&
                      value.length === 4 &&
                      Number(value) >= 0 &&
                      Number(value) <= 9999
                    ) {
                      return true;
                    }
                    return '비밀번호는 4자리로 설정해주세요';
                  },
                }}
                render={({ field }) => (
                  <>
                    <Input
                      type="text"
                      pattern="\d*"
                      maxLength={4}
                      placeholder="숫자 4자리를 입력해주세요."
                      isError={Boolean(errors.password?.message)}
                      {...field}
                    />
                    {errors.password?.message && (
                      <ErrorText>
                        <img
                          src={AlertIcon}
                          alt="경고 아이콘"
                          style={{ marginRight: '4px' }}
                        />
                        {errors.password.message as string}
                      </ErrorText>
                    )}
                  </>
                )}
              />
            )}
          </div>
          <ModifyButtonContainer>
            <ModifyButton onClick={handleSubmitClick}>수정하기</ModifyButton>
          </ModifyButtonContainer>
        </div>
        {/* 방 상태 변경 모달 */}
        {isStatusChangeModalOpen && (
          <StatusChangeModalBackground
            onClick={() => setIsStatusChangeModalOpen(false)}
          >
            <StatusChangeModalContainer>
              <StatusChangeModalContent onClick={(e) => e.stopPropagation()}>
                <StatusChangeModalTitle>모집 상태 설정</StatusChangeModalTitle>
                <StatusItem
                  onClick={() => {
                    setSelectedStatus('Waiting');
                    setIsStatusChangeModalOpen(false);
                  }}
                  selected={selectedStatus === 'Waiting'}
                >
                  대기중
                </StatusItem>
                <StatusItem
                  onClick={() => {
                    setSelectedStatus('Playing');
                    setIsStatusChangeModalOpen(false);
                  }}
                  selected={selectedStatus === 'Playing'}
                >
                  진행중
                </StatusItem>
              </StatusChangeModalContent>
              <ModalCancelButton>닫기</ModalCancelButton>
            </StatusChangeModalContainer>
          </StatusChangeModalBackground>
        )}
      </PlayLayout>
      <CustomSheet
        isOpen={isUserCountSheetOpen}
        onClose={() => setIsUserCountSheetOpen(false)}
        snapPoints={[0.4]}
      >
        <Sheet.Container>
          <Sheet.Header>
            <CustomDragIndicatorContainer>
              <CustomDragIndicator />
            </CustomDragIndicatorContainer>
          </Sheet.Header>
          <Sheet.Content>
            <SheetHeader title="인원 수 설정" />
            <WheelPicker
              data={userCountData}
              height={200}
              selectedID={String(selectedUserCount)}
              onChange={(target) => {
                selectedUserCountRef.current = Number(target.id);
              }}
              itemHeight={40}
              backgroundColor="#fff"
              color="#191919"
            />
            <ControllerContainer>
              <ControlButton
                onClick={handleClose}
                style={{ backgroundColor: '#F2F2F2' }}
              >
                닫기
              </ControlButton>
              <ControlButton
                onClick={handleUserCountChangeClick}
                style={{ backgroundColor: '#c869ff', color: '#fff' }}
              >
                확인
              </ControlButton>
            </ControllerContainer>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={() => setIsUserCountSheetOpen(false)} />
      </CustomSheet>
      <Modal {...modal} />
    </>
  );
}

const Button = styled('button', {
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
});

const Label = styled('span', {
  fontSize: '14px',
  fontWeight: 400,
});

const Content = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '56px',
});

const SelectButton = styled('button', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
});

const ModifyButtonContainer = styled('div', {
  width: '100%',
  padding: '8px 16px',
  borderTop: '1px solid #ebeced',
});

const ModifyButton = styled('button', {
  width: '100%',
  padding: '13px 0',
  backgroundColor: '#c869ff',
  color: '#fff',
  border: 'none',
  borderRadius: '99px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  cursor: 'pointer',
});

const DisabledText = styled('span', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#A7A8A9',
});

const CustomSheet = styled(Sheet, {
  margin: '0 auto',
  maxWidth: '500px',

  '.react-modal-sheet-backdrop': {
    /* custom styles */
  },
  '.react-modal-sheet-container': {
    /* custom styles */
  },
  '.react-modal-sheet-header': {
    /* custom styles */
  },
  '.react-modal-sheet-drag-indicator': {
    /* custom styles */
  },
  '.react-modal-sheet-content': {
    /* custom styles */
    flexShrink: 1,
  },
});

const CustomDragIndicatorContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '20px',
});

const CustomDragIndicator = styled('div', {
  width: '32px',
  height: '4px',
  borderRadius: '2px',
  backgroundColor: '#EBECED',
});

const ControllerContainer = styled('div', {
  position: 'absolute',
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '8px 16px',
  borderTop: '1px solid #ebeced',
});

const ControlButton = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  padding: '13px 69.25px',
  borderRadius: '999px',

  '&:first-of-type': {
    marginRight: '8px',
  },
});

const ErrorText = styled('span', {
  display: 'flex',
  alignItems: 'center',
  marginTop: '8px',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  color: '#F54E54',
});

// 방 상태 설정을 위한 모달. 컴포넌트로 빼야하나?
const StatusChangeModalBackground = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
});

const StatusChangeModalContainer = styled('div', {
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  padding: '8px',
  paddingBottom: '16px',
});

const StatusChangeModalContent = styled('div', {
  position: 'relative',
  width: '100%',
  backgroundColor: '#fff',
  borderRadius: '8px',
});

const StatusChangeModalTitle = styled('div', {
  padding: '14px 16px',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#a7a8a9',
});

const StatusItem = styled('div', {
  padding: '14px 16px',
  borderTop: '1px solid #F2F2F2',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  color: '#D6D6D8',
  cursor: 'pointer',

  variants: {
    selected: {
      true: {
        color: '#D6D6D8',
      },
      false: {
        color: '#000',
      },
    },
  },
});

const ModalCancelButton = styled('button', {
  width: '100%',
  padding: '13px 16px',
  marginTop: '8px',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#a7a8a9',
  backgroundColor: '#fff',
  outline: 'none',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
});
